<template>
  <div class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Role User
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <Form
          :name="roleName"
          :selected="menuAccess"

          @update:menuSelected="val => menuAccess = val"
          @update:name="val => roleName = val"
        />

        <div class="d-flex justify-content-end mt-1 bg-white p-2 shadow-lg rounded">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click="$router.back()"
          >
            Kembali
          </button>
          <button
            class="button-primary w-[200px]"
            :disabled="invalid || loadingSubmit || menuAccess.length === 0"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Simpan
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertSuccess, alertError } from '@/@core/utils/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Form from './Form.vue'

export default {
  components: {
    Form,
    ValidationObserver,
  },
  data() {
    return {
      loadingSubmit: false,
      loading: false,
      menuAccess: [],
      roleName: '',
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async submit() {
      const payload = {
        role_access_name: this.roleName,
        menu_access: this.menuAccess,
      }

      const url = 'v1/role_access/store'
      await komtimAxiosIns
        .post(url, payload)
        .then(res => {
          const text = 'Berhasil menambahkan data'

          alertSuccess(text)
          this.$router.back()
        })
        .catch(error => {
          this.loadingSubmit = false

          if (error.response.status === 500) this.$toast_error({ message: 'Internal Server error' })
          else this.alertError(error)
        })
    },
  },
}
</script>
